@font-face {
    font-family: 'Helvetica Neue Bold';
    src: local('Helvetica Neue Bold'), url(./HelveticaNeueBold.ttf) format('truetype');
}

@font-face {
    font-family: 'Helvetica Neue Medium';
    src: local('Helvetica Neue Medium'), url(./HelveticaNeueMedium.ttf) format('truetype');
}

@font-face {
    font-family: 'SF Pro Bold';
    src: local('SF Pro Bold'), url(./SFProBold.OTF) format('truetype');
}

@font-face {
    font-family: 'SF Pro Medium';
    src: local('SF Pro Medium'), url(./SFProMedium.OTF) format('truetype');
}

@font-face {
    font-family: 'SF Pro Light Italic';
    src: local('SF Pro Light Italic'), url(./SFProLightItalic.OTF) format('truetype');
}

@font-face {
    font-family: 'SF Pro Regular';
    src: local('SF Pro Regular'), url(./SFProRegular.OTF) format('truetype');
}

h1, h2, h3, h4, h5, h6 {
    font-family: SF Pro Bold;
}

span, p, small {
    font-family: SF Pro Medium;
}

.light-italic {
    font-family: SF Pro Light Italic;
}

.font-regular {
    font-family: SF Pro Regular;
}

button {
    font-family: SF Pro Bold;
}

button:focus {
    outline: none !important;
}

button:not(.disable):hover {
    background: var(--primary);
}

.w-fit-content {
    width: fit-content;
}

.transform-scale-1\.1 {
    transform: scale(1.15) !important;
}

.transform-scale-0\.9 {
    transform: scale(0.9);
}

.custom-shadow {
    box-shadow: 0px 0px 50px 8px #DEDEDE;
}