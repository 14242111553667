.z-1 {
    z-index: 1;
}

.planet {
    background: radial-gradient(#3f1b87, #000228);
    /* background-image: url('assets/img/planet.png');
    background-repeat: no-repeat;
    background-size: cover; */
}

.galaxy {
	background-image: url('assets/img/galaxy_background.png');
	background-repeat: no-repeat;
	background-size: cover;
	top: 0;
	left: 0;
	width: 100%;
	background-attachment: fixed;
	height: 100%;
}

.bg-product {
	background-image: url('assets/img/fog_background.png');
	background-repeat: repeat-y;
	background-position: center;
	background-size: inherit;
}


.rocket {
    animation-duration: 3s;
    animation-name: fly;
    animation-iteration-count: infinite;
    animation-direction: alternate;
}

@-webkit-keyframes fly {
	from { 	-webkit-transform: rotate(270deg) translate3d(-5vh, 0px, 0px) rotate(90deg); }
	/* 25% { 	-webkit-transform: translate3d(15vh, -5vh, 0px); } */
	/* 50%   {  -webkit-transform: translate3d(25vh, -10vh, 0px) rotate(45deg) ; }
	75%   {  -webkit-transform: translate3d(75vh, -5vh, 0px) rotate(45deg) ; } */
	to   {  -webkit-transform: rotate(360deg) translate3d(75vh, 30vh, 0px) rotate(90deg); }
}

@-moz-keyframes fly {
	from { 	-moz-transform: rotate(270deg) translate3d(-5vh, 0px, 0px) rotate(90deg); }
	/* 25% { 	-moz-transform: translate3d(15vh, -5vh, 0px); } */
	/* 50%   {  -moz-transform: translate3d(25vh, -10vh, 0px) rotate(45deg) ; }
	75%   {  -moz-transform: translate3d(75vh, -5vh, 0px) rotate(45deg) ; } */
	to   {  -moz-transform: rotate(360deg) translate3d(75vh, 30vh, 0px) rotate(90deg); }
}

@-o-keyframes fly {
	from { 	-o-transform: rotate(270deg) translate3d(-5vh, 0px, 0px) rotate(90deg); }
	/* 25% { 	-o-transform: translate3d(15vh, -5vh, 0px); } */
	/* 50%   {  -o-transform: translate3d(25vh, -10vh, 0px) rotate(45deg) ; }
	75%   {  -o-transform: translate3d(75vh, -5vh, 0px) rotate(45deg) ; } */
	to   {  -o-transform: rotate(360deg) translate3d(75vh, 30vh, 0px) rotate(90deg); }
}

@keyframes fly {
	from { 	transform: rotate(270deg) translate3d(-5vh, 0px, 0px) rotate(90deg); }
	/* 25% { 	transform: translate3d(15vh, -5vh, 0px); } */
	/* 50%   {  transform: translate3d(25vh, -10vh, 0px) rotate(45deg) ; }
	75%   {  transform: translate3d(75vh, -5vh, 0px) rotate(45deg) ; } */
	to   {  transform: rotate(360deg) translate3d(75vh, 30vh, 0px) rotate(90deg); }
}


::-webkit-scrollbar {
	width: 10px;
}
  
::-webkit-scrollbar-track {
	background: #3f1b87; 
}
   
::-webkit-scrollbar-thumb {
	background: #b81e7e; 
	border-radius: 50px;
}
  
::-webkit-scrollbar-thumb:hover {
	background: #3f1b87; 
}

.hover\:rotateZ:hover {
	transition-duration: 200ms;
	transform: rotate3d(0, 1, 0, 180deg);
}

.core-shadow {
	box-shadow: 15px -8px 15px 0px black;
}

.glowing-shadow {
	box-shadow: 0px 0px 30px 5px white;
}

.tb-rl {
	writing-mode: tb-rl;
}