:root {
    --primary: #6495ed;
    --primary-light: #6495ed;
}

.bg-primary {
    background: var(--primary);
}

.border-primary {
    border-color: var(--primary);
}

.text-primary-light {
    color: var(--primary-light);
}

.text-primary {
    color: var(--primary);
}

.border-primary-light {
    border-color: var(--primary-light);
}